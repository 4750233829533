/*jslint esnext: true */
/*global jQuery, dis, _, Modernizr */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: Morsø municipality
 Date of creation: Dec. 16th, 2015
 */


(function ($, dis) {
    "use strict";


    /**
     * This module adds the "auto-truncate" function, which can cut down a list of existing entries and reveal them
     * as needed.
     *
     * @param objectConfiguration - The configuration for the object
     * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
     * @constructor
     * @extends dis.BaseModule
     */
    dis.ui.Autotruncate = function (objectConfiguration) {

        // Fire the basemodule-initiator on this module.
        dis.BaseModule.call(this);

        var defaults = {
                targetSelector: "> *",
                itemsPerPage: 6
            },
            configuration = $.extend(defaults, objectConfiguration),
            dom = {},
            selfScope = this;


        /**
         * Reveal a new subset of the items we know of.
         *
         * @param {Event} e
         */
        function revealMoreItems(e) {
            if (e && e.preventDefault) {
                e.preventDefault();
            }

            // Get all the entries that are still hidden
            var newSubset = dom.entries.filter(".hidden");

            // Reveal the next set of entries.
            newSubset.slice(0, configuration.itemsPerPage).removeClass("hidden").addClass("reveal-from-bottom");

            // Show or hide the "show more"-button, depending on whether or not there are hidden entries still.
            dom.showMoreButton.toggleClass("hidden", newSubset.length <= configuration.itemsPerPage);

            // Trigger a resize-event on the browser to ensure that the sidebar knows the page height has changed.
            selfScope.globalDOM.window.trigger("resize");
        }



        function reInit() {
            // Find all the entries we should work on
            dom.entries = dom.container.find(configuration.targetSelector);

            // Only continue if there are more entries than our limit allows.
            if (dom.entries.length > configuration.itemsPerPage) {

                // Hide all entries that fall outside the limit.
                dom.entries.removeClass("hidden").slice(configuration.itemsPerPage).addClass("hidden");

                // Reveal the "show more"-button.
                dom.showMoreButton.removeClass("hidden");

                // Trigger a resize-event on the browser to ensure that the sidebar knows the page height has changed.
                selfScope.globalDOM.window.trigger("resize");
            } else {
                dom.showMoreButton.addClass("hidden");
            }
        }



        /**
         * Initialization function, which is run when the module is "booting".
         */
        function init() {

            dom.container = $(configuration.container);

            if (dom.container.length) {

                // Get the target selector we should use
                configuration.targetSelector = dom.container.attr("data-autotruncate-target") || configuration.targetSelector;

                // Find out how many items we should reveal at any one time.
                configuration.itemsPerPage = Number(dom.container.attr("data-autrotruncate")) || configuration.itemsPerPage;
                if (isNaN(configuration.itemsPerPage)) {
                    configuration.itemsPerPage = 5;
                }

                // Find the "show more"-button, which should exist somewhere in the container.
                dom.showMoreButton = dom.container.find("button.search-results__show-more");
                dom.showMoreButton.click(revealMoreItems);

                dom.container.on("refresh.autotruncate.dis", reInit);

                reInit();
            }

        }

        // Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
        // in dis.base.js.
        selfScope.onReady(init);

    };

    dis.ui.Autotruncate.prototype = new dis.BaseModule();
    dis.ui.Autotruncate.constructor = dis.ui.Autotruncate;

}(jQuery, dis));