/*global jQuery, dis, imagesLoaded, _ */

/* DIS/PLAY Script
 Author's name: Bjarni Olsen, bjo
 Modified by: Anders Gissel
 Date of creation: 10/04 2015
 */

(function ($, dis, imagesLoaded, underscore) {
    "use strict";

    /**
     * This module loops the dom to collect image wrapper classes.
     * It then adds the images (normal img tags and background images) to the dom
     * with a src based on screen width.
     *
     * @constructor
     * @extends dis.BaseModule
     */
    dis.ImageHandler = function (objectConfiguration) {

        dis.BaseModule.call(this);

        var defaults = {
                loadingClassname: "loadingImage",
                loadedClassname: "loaded"
            },
        // Define the actual configuration for this instantiation
            configuration = $.extend(defaults, objectConfiguration),
            lastUsedScreenWidth,
            dom = {},
            eventHandlers,
            selfScope = this;



        /**
         * This function gets the data-src from the image wrapper, based on width of the browser window.
         *
         * @param {jQuery} image - Image wrapper element
         * @returns {string}
         */
        function getImageSrc(image) {

            var src = image.attr("data-src-xs") || "",
                currentScreenMode = selfScope.currentScreenMode;

            if (currentScreenMode >= selfScope.screenSizes.tablet) {
                src = image.attr("data-src-sm") || src;
            }

            if (currentScreenMode >= selfScope.screenSizes.desktop) {
                src = image.attr("data-src-md") || src;
            }

            if (currentScreenMode >= selfScope.screenSizes.largeDesktop) {
                src = image.attr("data-src-lg") || src;
            }

            return src;
        }



        /**
         * This function gets the image wrapper data atrributes src and alt text,
         * creates a new image and appends it to image wrapper.
         *
         * @param {HTMLElement|jQuery|string|*} currentImage - Image wrapper element
         */
        function loadImage(currentImage) {
            var imageContainer = $(currentImage),
                src = getImageSrc(imageContainer),
                newImageTag,
                altText,
                oldImage;

            // We don't want to start processing if the new URL matches the old one.
            oldImage = imageContainer.find("img." + configuration.loadedClassname);
            if (oldImage.length) {
                if (oldImage.attr("src") === src) {
                    return;
                }
            }

            newImageTag = new Image();
            newImageTag.setAttribute("class", configuration.loadedClassname);

            // Set the ALT text.
            altText = currentImage.getAttribute("data-alt") || "";
            newImageTag.setAttribute("alt", altText);

            imageContainer.addClass(configuration.loadingClassname);

            // Utilize "imagesLoaded()" helper to determine when the image has actually been loaded.
            imagesLoaded(newImageTag, function () {
                oldImage.remove();
                imageContainer.removeClass(configuration.loadingClassname);
                currentImage.appendChild(newImageTag);
            });
            newImageTag.src = src;
        }



        /**
         * This function gets the image wrapper data atrributes src and alt text
         * and creates an new image tag to download the image.
         * It then uses the src as a background-image.
         *
         * @param {HTMLElement|jQuery|string|*} currentContainer - Image wrapper element
         */
        function loadBgImage(currentContainer) {
            var imageContainer = $(currentContainer),
                imageLoaderTag,
                src,
                formattedSrc;

            src = getImageSrc(imageContainer);
            formattedSrc = "url(" + src + ")";


            if (imageContainer.css("background-image") === formattedSrc) {
                return;
            }


            imageContainer.addClass(configuration.loadingClassname);
            imageLoaderTag = new Image();

            // Utilize "imagesLoaded()" helper to determine when the image has actually been loaded.
            imagesLoaded(imageLoaderTag, function () {
                imageContainer.attr('style', 'background-image: ' + formattedSrc);
                imageContainer.addClass(configuration.loadedClassname);
                imageContainer.removeClass(configuration.loadingClassname);
            });

            // Start loading the new image.
            imageLoaderTag.src = src;
        }


        eventHandlers = {
            refreshAll: function () {
                // If our current screen mode does not match the one we used the last time we made an image lookup,
                // perform a new one now. Otherwise, what would be the point?
                if (lastUsedScreenWidth !== selfScope.currentScreenMode) {
                    underscore.each(dom.images, loadImage);
                    underscore.each(dom.bgImages, loadBgImage);
                    lastUsedScreenWidth = selfScope.currentScreenMode;
                }
            }
        };


        this.refreshImages = function () {
            dom.images = $(".imagehandler");
            dom.bgImages = $(".bg-imagehandler");
            lastUsedScreenWidth = -1;
            eventHandlers.refreshAll();
        };


        function init() {
            selfScope.onWindowResize(eventHandlers.refreshAll);
            selfScope.refreshImages();
        }

        // Fire our init-function once our framework and DOM is ready.
        selfScope.onReady(init);
    };

    dis.ImageHandler.prototype = new dis.BaseModule();
    dis.ImageHandler.constructor = dis.ImageHandler;

}(jQuery, dis, imagesLoaded, _));
