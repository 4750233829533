/*global jQuery, dis, svg4everybody, Modernizr */

/* DIS/PLAY Script
 Author's name: ...
 Modified by:
 Client name: ...
 Date of creation: ...
 */

(function ($, dis) {
    "use strict";


    function init() {

        // Initialize various support modules. First the image handler.
        var imagehandler = new dis.ImageHandler(),
            dynamicHeight = new dis.DynamicHeight(),
            // Then the header controller.
            headerHandler = new dis.ui.Header({container: $("#headerWrapper")}),
            // Then a handler for miscellaneous UI-stuff, too small to warrant their own constructor.
            miscellaneousHandler = new dis.ui.Miscellaneous();


        // We might need a helper-class for mobile Safari.
        if (dis.BaseModule.prototype.isMobileSafari) {
            $("html").addClass("mobile-safari");
        }


        // Let's create instantiations for employee search where needed.
        $("[data-employeesearch]").each(function () {
            var employeeSearch = new dis.EmployeeSearch({container: this});
        });

        // Ditto for autotruncate-elements
        $("[data-autotruncate]").each(function () {
            var truncator = new dis.ui.Autotruncate({container: this});
        });

        // Ditto for autotruncate-elements
        $("[data-autocomplete]").each(function () {
            var autocomplete = new dis.Autocomplete({container: this});
        });

        // Ditto for Google maps layer module
        $("[data-googlemapslayers]").each(function () {
            var googlemapslayers = new dis.GoogleMapsLayers(this);
        });


        // Allow animation on elements requesting it, after a short timeout. This is to avoid
        // flashes of animation once the stylesheet loads.
        window.setTimeout(function () {
            $(".defer-animation").removeClass("defer-animation").addClass("allow-animation");
        }, 500);


        // Load SVGs, in case the browser can't handle it itself.
        window.svg4everybody();
    }


    // Initialize the main script(s) once the DOM is ready.
    dis.BaseModule.prototype.onReady(function () {
        init();
    });


}(jQuery, dis));

