/*jslint esnext: true */
/*global jQuery, dis, _, Modernizr, google */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: Morsø municipality
 Date of creation: Jan. 12th, 2015
 */


var apiLoaderPromise,
    googleAPIKey;


function loadMapsAPI() {

    if (!googleAPIKey) {
        throw "Google API Key hasn't been set. You need to set it before actually loading the API.";
    }

    if (!apiLoaderPromise) {
        apiLoaderPromise = new Promise(apiIsReady => {

            // Set up a listener for when the map API is ready for us.
            window.akqaUtilsGoogleMapsOnReady = function() {
                apiIsReady(window.google.maps);
            };

            // Append the API script tag to the page.
            const scriptTag = document.createElement("script");
            scriptTag.src = "https://maps.googleapis.com/maps/api/js?key=" + googleAPIKey + "&callback=akqaUtilsGoogleMapsOnReady";
            scriptTag.async = true;
            scriptTag.defer = true;

            document.body.appendChild(scriptTag);

        });
    }

    return apiLoaderPromise;

}




(function ($, dis, underscore) {
    "use strict";


    /**
     * This module handles the employee search form and result rendering.
     *
     * @param {jQuery|Element|string|*} container - The DOM-container for this instantiation
     * @constructor
     * @extends dis.BaseModule
     */
    dis.GoogleMapsLayers = function (container) {

        // Fire the basemodule-initiator on this module.
        dis.BaseModule.call(this);

        var dom = {
                container: $(container)
            },
            knownLayers = [],
            builtLayers = {},
            geoLocationLayers = [],
            eventHandlers,
            selfScope = this,
            LAYER_TYPES = {
                ROUTE: "route",
                POINT: "point",
                SHAPE: "shape"
            },

            // Defining googleMap object
            mapOptions = {
                center: {
                    lat: Number(dom.container.data("latitude")) || 56.826924,
                    lng: Number(dom.container.data("longitude")) || 8.5857632
                },
                zoom: Number(dom.container.data("zoom")) || 11,
                mapTypeControl: false,
                panControl: false,
                streetViewControl: false,
                zoomControl: false,
                mapTypeId: 'satellite'
            },
            mapInstance;

        googleAPIKey = dom.container.data("key");



        function translateColorNameToHexValue(colorName) {
            switch (colorName.toLowerCase() ) {
                case "deepblue": return "#303192";
                case "orange": return "#F18E18";
                case "green": return "#36B549";
                case "teal": return "#74ABB7";
                case "red": return "#D2232A";
                case "yellow": return "#FEC20B";
                case "palegreen": return "#83CA9D";
                case "babyblue": return "#80AECD";
                case "black": return "#333";
                case "lightgray": return "#eee";
                default: return "#f00";
            }
        }




        function buildSingleToggler(data) {

            var description = data.description.toString().trim();

            var togglerHtml = "<div class='google-maps-layers__single-toggler'>";


            if (description) {
                togglerHtml += "<input type='checkbox' class='google-maps-layers__text-checkbox' id='mapTxtExpand-" + data.internalID + "' data-id='" + data.internalID + "'>" +
                "                <div class='google-maps-layers__text-wrapper'>" +
                "                    <div class='google-maps-layers__expand-text'><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox='0 0 15.485 9.193'><path d='M7.743 9.157l-.036.036-1.414-1.415.035-.035-6.329-6.329L1.414 0l6.328 6.328L14.07 0l1.414 1.414-6.328 6.329.035.035-1.414 1.415z'/></svg></div>" +
                "                    <label class='google-maps-layers__text-label google-maps-layers__text-label--clickable' for='mapTxtExpand-" + data.internalID + "'></label>" +
                "                    <div class='google-maps-layers__body-text'>" + description + "</div>" +
                "                </div>";
            } else {
                togglerHtml += "<div class='google-maps-layers__text-wrapper'>" +
                    "                    <div class='google-maps-layers__text-label'></div>" +
                    "                </div>";
            }

            togglerHtml += "<div class='google-maps-layers__toggle-switch" + (data.color ? " google-maps-layers__toggle-switch--" + data.color.toString().toLowerCase() : "") + "'>" +
                "                    <label class='switch'>" +
                "                        <input type='checkbox' checked class='google-maps-layers__toggle-checkbox' data-id='" + data.internalID + "'>" +
                "                        <span class='slider round'>" +
                "                            <span class='slider-bullet'><svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 14.779 10.607\"><path d=\"M5.622 10.571l-.036.036-1.414-1.415.035-.035-4.208-4.208 1.415-1.414 4.207 4.207L13.363 0l1.415 1.414-7.743 7.743.035.035-1.414 1.415z\"/></svg></span>" +
                "                        </span>" +
                "                        <span class='switch__background'></span>" +
                "                    </label>" +
                "                </div>" +
                "            </div>";


            var parsedData = $(togglerHtml);

            // Set the title and headline here to avoid trouble in case they decide to use weird characters in their titles.
            parsedData.find(".google-maps-layers__text-label").attr("title", data.headline).text(data.headline);

            return parsedData;
        }


        /**
         * Initialize Google Maps by ensuring the script is loaded, and then return a promise that resolves once
         * the map is actually ready.
         *
         * @returns {Promise}
         */
        function initializeGoogleMaps() {

            return loadMapsAPI().then(function () {
                mapInstance = new google.maps.Map(dom.mapContainer.get(0), mapOptions);
            });

        }


        /**
         * Build (or return previously built) "layer" of position markers.
         *
         * @param {string} layerID - Internal ID of the layer to build markers for.
         * @returns {Array} Array of markers built for the given layer, for easier visibility changes.
         */
        function getMarkerLayer(layerID) {

            // If no layer exists yet, build it now.
            if (!builtLayers[layerID]) {
                var newData = [];

                var icon = {
                    url: window.location.origin + "/static/img/markers/marker-" + knownLayers[layerID].color.toLowerCase() + ".png",
                    scaledSize: new google.maps.Size(43, 48),
                    anchor: new google.maps.Point(22, 32)
                };

                // Create some markers, yo!
                knownLayers[layerID].points.forEach(function (point) {

                    var infoWindow = new google.maps.InfoWindow({
                        content: (point.title ? "<h4 style='margin-top: .3em'>" + point.title.replace("<", "&lt;") + "</h4>" : "") + point.description
                    });

                    var newMarker = new google.maps.Marker({
                        position: {
                            lat: point.lat,
                            lng: point.lng
                        },
                        icon: point.iconUrl || icon,
                        title: point.title || "",
                        map: mapInstance
                    });

                    newMarker.addListener('click', function() {
                        infoWindow.open(mapInstance, newMarker);
                    });

                    newData.push(newMarker);
                    newData.push(infoWindow);
                });

                // Store the newly built array of markers.
                builtLayers[layerID] = newData;
            }

            return builtLayers[layerID];

        }


        /**
         * Build (or return previously built) "layer" containing a route.
         *
         * @param {string} layerID - Internal ID of the layer to build the route for.
         * @returns {Array} An array (for later manipulation purposes) with a single entry: the Google PolyLine object containing the route in question.
         */
        function getRouteLayer(layerID) {

            if (!builtLayers[layerID]) {
                var newData = new google.maps.Polyline({
                    map: mapInstance,
                    path: knownLayers[layerID].coordinates,
                    strokeColor: translateColorNameToHexValue(knownLayers[layerID].color),
                    strokeOpacity: 1.0,
                    strokeWeight: 5
                });

                builtLayers[layerID] = [newData];
            }

            return builtLayers[layerID];

        }

        /**
         * Build (or return previously built) "layer" containing a shape.
         *
         * @param {string} layerID - Internal ID of the layer to build the shape for.
         * @returns {Array} An array (for later manipulation purposes) with a single entry: the Google Polygon object containing the shape in question.
         */
        function getShapeLayer(layerID) {

            if (!builtLayers[layerID]) {
                var newData = new google.maps.Polygon({
                    map: mapInstance,
                    path: knownLayers[layerID].coordinates,
                    strokeColor: translateColorNameToHexValue(knownLayers[layerID].color),
                    strokeOpacity: 1.0,
                    strokeWeight: 5,
                    fillColor: translateColorNameToHexValue(knownLayers[layerID].color),
                    fillOpacity: 0.35
                });

                var shapeInfoWindow = new google.maps.InfoWindow({
                    content: (knownLayers[layerID].headline ? "<h4 style='margin-top: .3em'>" + knownLayers[layerID].headline.replace("<", "&lt;") + "</h4>" : "") + knownLayers[layerID].description
                });

                var bound = new google.maps.LatLngBounds();

                knownLayers[layerID].coordinates.forEach(coordinate => {
                    bound.extend(coordinate);
                });

                newData.addListener('click', function() {
                    shapeInfoWindow.setPosition(bound.getCenter());
                    shapeInfoWindow.open(mapInstance, newData);
                });

                builtLayers[layerID] = [newData];
            }

            return builtLayers[layerID];
        }


        /**
         *
         * @param {string[]} layerIDsToShow - Array of IDs of the "layers" that should be visible. Any IDs not inside this array will be hidden automatically.
         */
        function showSelectedLayers(layerIDsToShow) {

            // Hide all known layers that aren't part of the given ID array.
            $.each(builtLayers, function (layerID, layerContent) {
                if (layerIDsToShow.indexOf(layerID) === -1) {
                    layerContent.forEach(function (contentPart) {
                        if (typeof contentPart.setVisible === "function") {
                            contentPart.setVisible(false);
                        } else if (typeof contentPart.close === "function") {
                            contentPart.close();
                        }
                    });
                }
            });

            // Set up an object of lat/lng bounds so we can easily zoom the map to all the entries it'll contain.
            var bounds = new google.maps.LatLngBounds();


            // Run through and show the layers we need.
            layerIDsToShow.forEach(function (layerID) {
                var layerData = knownLayers[layerID];
                var layerContent;

                // Routes are here.
                if (layerData.internalType === LAYER_TYPES.ROUTE) {
                    layerContent = getRouteLayer(layerID)[0];
                    layerContent.setVisible(true);

                    // Get all the points inside the route, and add them to the bounds-object in order to show it on the map.
                    var routePathArray = layerContent.getPath().getArray();
                    routePathArray.forEach(function (point) {
                        bounds.extend(point);
                    });

                }


                // Markers are here.
                else if (layerData.internalType === LAYER_TYPES.POINT) {
                    layerContent = getMarkerLayer(layerID);

                    // Show all markers in the current layer, and add them to the bounds-object so they'll be visible.
                    layerContent.forEach(function (contentPart) {

                        // Make sure we don't try to manipulate stuff that isn't a marker... like, say, an InfoWindow
                        // instance.
                        if (typeof contentPart.setVisible === "function") {
                            contentPart.setVisible(true);
                            bounds.extend(contentPart.position);
                        }
                    });
                }

                else if (layerData.internalType === LAYER_TYPES.SHAPE) {
                    layerContent = getShapeLayer(layerID)[0];
                    layerContent.setVisible(true);

                    // Get all the points inside the shape, and add them to the bounds-object in order to show it on the map.
                    var shapePathArray = layerContent.getPath().getArray();
                    shapePathArray.forEach(function (point) {
                        bounds.extend(point);
                    });
                }


            });

            // Zoom the map
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    var pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    bounds.extend(pos);

                    if (geoLocationLayers.length === 0) {

                        // Limit the accurracy to prevent an enormous circle when the geolocation is very inaccurate
                        var accuracy = position.coords.accuracy > 5000 ? 5000 : position.coords.accuracy;
                        
                        var icon = {
                            url: window.location.origin + "/static/img/markers/marker-geolocation.png",
                            scaledSize: new google.maps.Size(43, 48),
                            anchor: new google.maps.Point(22, 32)
                        };
                        
                        var infoWindow = new google.maps.InfoWindow({
                            content: "<h4 style='margin-top: .3em'>Din placering</h4>"
                        });

                        var locationMarker = new google.maps.Marker({
                            position: pos,
                            icon: icon,
                            title: "Din placering",
                            map: mapInstance
                        });
                        
                        // Accuracy circle
                        var accuracyCircle = new google.maps.Circle({
                            center: pos,
                            radius: accuracy,
                            map: mapInstance,
                            fillColor: '#36B549',
                            fillOpacity: 0.2,
                            strokeColor: '#36B549',
                            strokeOpacity: 0.5
                        });

                        geoLocationLayers.push(locationMarker, accuracyCircle);
                        
                        locationMarker.addListener('click', function() {
                            infoWindow.open(mapInstance, locationMarker);
                        });
                    }

                    mapInstance.fitBounds(bounds);
                }, function() {
                    if (!bounds.isEmpty()) {
                        mapInstance.fitBounds(bounds);
                    }
                });
              } else if (!bounds.isEmpty()) {
                    // Zoom the map to the visible entries... if there are any.
                    mapInstance.fitBounds(bounds);
                }
        }




        eventHandlers = {

            updateLayers: function (e) {
                e.preventDefault();

                // Get all the IDs of the selected checkboxes.
                var selectedLayerIDs = dom.layerToggleCheckboxes.filter(":checked").get().map(function (checkbox) {
                    return checkbox.getAttribute("data-id").toString();
                });

                // Show 'em!
                showSelectedLayers(selectedLayerIDs);
            },


            toggleBodyText: function () {
                var element = this;
                if (element.checked) {
                    dom.textBoxToggleCheckboxes.not(element).prop("checked", false);
                }
            }

        };



        /**
         * Initialization function, which is run when the module is "booting".
         */
        function init() {

            if (dom.container.length) {

                // The bread and the butter of this whole thing. The JSON feed
                dom.jsonData = window.kmlJson;

                // Caching the dom
                dom.toggleWrapper = dom.container.find(".google-maps-layers__toggle-wrapper");
                dom.mapContainer = dom.container.find(".google-maps-layers__map");

                // Init the google map
                initializeGoogleMaps().then(function () {

                    // Empty the toggle-wrapper, since we're about ready to put stuff into it.
                    dom.toggleWrapper.empty();

                    // Make sure the toggle-wrapper, which is really a form, can't submit.
                    dom.toggleWrapper.on("submit", eventHandlers.updateLayers);

                    // Parse the map JSON data, cache the routes and marker objects, and build the toggle
                    // boxes, all in one go. Oh my!
                    Object.keys(dom.jsonData).forEach(function (groupType) {
                        var layersInGroup = dom.jsonData[groupType];

                        // Cache what type of layer we're dealing wíth here.
                        var groupTypeIdentifier = groupType === "routeLayers" ? LAYER_TYPES.ROUTE :
                            groupType === "pointLayers" ? LAYER_TYPES.POINT :
                            groupType === "shapeLayers" ? LAYER_TYPES.SHAPE 
                            : undefined;

                        layersInGroup.forEach(function (layer) {
                            // Build a "reference" we can re-use for every interaction from this point.
                            layer.internalID = knownLayers.length.toString();
                            layer.internalType = groupTypeIdentifier;

                            // Build the toggle switch thingie.
                            dom.toggleWrapper.append(buildSingleToggler(layer));

                            // Store the layer for later re-use.
                            knownLayers.push(layer);
                        });

                    });


                    // Now that we have all the checkboxes we need, let's set up an event on them to update the map
                    // whenever the layer information changes.
                    dom.layerToggleCheckboxes = dom.toggleWrapper.find("input.google-maps-layers__toggle-checkbox");
                    dom.layerToggleCheckboxes.on("click", function () {
                        dom.toggleWrapper.submit();
                    });


                    // Oh, there's also some checkboxes for expanding the info window thingies. So we should deal with
                    // those, too.
                    dom.textBoxToggleCheckboxes = dom.toggleWrapper.find(".google-maps-layers__text-checkbox");
                    dom.textBoxToggleCheckboxes.on("click", eventHandlers.toggleBodyText);


                    // Now that everything is ready, "submit" the form to build and show all the layers at once.
                    dom.toggleWrapper.submit();

                });

            }
        }

        // Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
        // in dis.base.js.
        selfScope.onReady(init);

    };

    dis.GoogleMapsLayers.prototype = new dis.BaseModule();
    dis.GoogleMapsLayers.constructor = dis.GoogleMapsLayers;

}(jQuery, dis, _));