/*jslint esnext: true */
/*global jQuery, dis, _, Modernizr */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: Morsø municipality
 Date of creation: Jan. 12th, 2015
 */


(function ($, dis, underscore) {
    "use strict";


    /**
     * This module handles the employee search form and result rendering.
     *
     * @param objectConfiguration - The configuration for the object
     * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
     * @constructor
     * @extends dis.BaseModule
     */
    dis.EmployeeSearch = function (objectConfiguration) {

        // Fire the basemodule-initiator on this module.
        dis.BaseModule.call(this);

        var defaults = {
                apiEndpoint: "/umbraco/morsoe/medarbejdere/find",
                showMoreLabel: "Vis flere",
                departmentLabel: 'Arbejdssted:',
                phoneLabel: 'Telefon:',
                emailLabel: 'E-mail:',
                titleLabel: 'Titel:'
            },
            configuration = $.extend(defaults, objectConfiguration),
            dom = {},
            eventHandlers,
            currentSearchValue = "",
            currentPage = 1,
            runningQuery,
            cachedQueries = {},
            totalPages = 0,
            selfScope = this;


        /**
         * Render an employee based on the incoming data
         * @param {object} employeeData
         * @returns {jQuery7|HTMLElement}
         */
        function renderEmployee(employeeData) {

            var outerItem = $('<div>', {'class': 'search-result'}),
                listContainer = $('<dl>', {'class': 'search-result__userdata'}),

                name = $('<div>', {'class': 'search-result__title', text: employeeData.displayName}),

                departmentLabel = $('<dt>', {text: configuration.departmentLabel}),
                department = $('<dd>', {text: employeeData.department}),

                emailLabel = $('<dt>', {text: configuration.emailLabel}),
                emailAddress = $('<dd>', {text: '-'}),

                phoneLabel = $('<dt>', {text: configuration.phoneLabel}),
                phoneNumber = $('<dd>', {text: '-'}),

                titleLabel = $('<dt>', {text: configuration.titleLabel}),
                title = $('<dd>', {text: employeeData.title || "-"});


            // Only render the e-mail link if the e-mail actually exists.
            if (employeeData.emailAddress && employeeData.emailAddress !== "-") {
                emailAddress.html('<a href="mailto:' + employeeData.emailAddress + '">' + employeeData.emailAddress + '</a>');
            }


            // Same for the phone number
            if (employeeData.telephoneNumber && employeeData.telephoneNumber !== "-") {
                phoneNumber.html('<a href="tel:' + employeeData.telephoneNumber + '">' + employeeData.telephoneNumber + '</a>');
            }


            if (employeeData.description && employeeData.description !== "-") {
                department.append($('<span>').text(" (" + employeeData.description + ")"));
            }


            // Append the various data to our content containers
            listContainer.append(departmentLabel, department, emailLabel, emailAddress, titleLabel, title, phoneLabel, phoneNumber);

            // Add the containers to our "parent item"
            outerItem.append(name, listContainer);

            // If we're not on page 1, we'll add a nifty little animation here.
            if (currentPage > 1) {
                outerItem.addClass("reveal-from-bottom");
            }

            return outerItem;
        }


        /**
         * Pretty much does what it says on the tin.
         */
        function loadDataFromServer() {

            // Only continue if we have a value to start from.
            if (currentSearchValue) {

                // Abort any old query that may be running still
                if (runningQuery && runningQuery.readyState !== 4) {
                    runningQuery.abort();
                }

                var serializedObjectKey = currentSearchValue + "___" + currentPage,
                    configObject;


                // Only run the actual server query if we haven't done it once already.
                if (!cachedQueries[serializedObjectKey]) {
                    configObject = {
                        q: currentSearchValue
                    };

                    if (currentPage !== 1) {
                        configObject.page = currentPage;
                    }

                    dom.showMoreButton.addClass("hidden");

                    cachedQueries[serializedObjectKey] = runningQuery = $.ajax({
                        url: configuration.apiEndpoint,
                        data: configObject,
                        dataType: "json"
                    });
                }


                // Set up a callback for the query - regardless of whether it's fresh or reused.
                cachedQueries[serializedObjectKey].then(function (response) {
                    totalPages = response.totalPages;

                    var renderedContent = underscore.map(response.items, renderEmployee);

                    if (currentPage === 1) {
                        dom.resultContainer.empty();
                    }

                    dom.resultContainer.append(renderedContent);

                    dom.showMoreButton.toggleClass("hidden", !response.links.next);

                    selfScope.globalDOM.window.trigger("resize");
                });

            }

        }



        eventHandlers = {

            searchFieldUpdated: function () {
                var newSearchValue = $.trim(dom.searchInputField.val());
                if (newSearchValue !== currentSearchValue) {
                    currentSearchValue = newSearchValue;
                    currentPage = 1;
                    loadDataFromServer();
                }
            },

            showMore: function () {
                currentPage += 1;
                loadDataFromServer();
            }

        };

        eventHandlers.throttledEvents = {
            searchFieldUpdated: underscore.debounce(eventHandlers.searchFieldUpdated, 300)
        };


        /**
         * Set up the search form.
         */
        function bootstrapForm() {

            // Bind events to the search input field
            dom.searchInputField = dom.form.find("input[type=search]");
            dom.searchInputField.bind("change keydown keyup keypress blur", eventHandlers.throttledEvents.searchFieldUpdated);

            // Stop the form from doing anything stupid.
            dom.form.submit(function (e) {
                e.preventDefault();
                eventHandlers.throttledEvents.searchFieldUpdated();
            });
        }




        /**
         * Initialization function, which is run when the module is "booting".
         */
        function init() {

            dom.container = $(configuration.container);

            if (dom.container.length) {

                // Find the search form and set it up.
                dom.form = $(dom.container.attr("data-employeesearch-form"));
                if (dom.form.length) {
                    bootstrapForm();
                }


                dom.resultContainer = $("<div></div>");

                dom.showMoreButton = $("<button>", { type: 'button', 'class': 'search-results__show-more hidden', text: configuration.showMoreLabel });
                dom.showMoreButton.click(eventHandlers.showMore);

                dom.container.empty();

                dom.container.append(dom.resultContainer, dom.showMoreButton);

                loadDataFromServer();

            }
        }

        // Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
        // in dis.base.js.
        selfScope.onReady(init);

    };

    dis.EmployeeSearch.prototype = new dis.BaseModule();
    dis.EmployeeSearch.constructor = dis.EmployeeSearch;

}(jQuery, dis, _));