/*global jQuery, dis*/

/* DIS/PLAY Script
 Author's name: Christoffer Poulsen
 Modified by:
 Date of creation: 28/09 2017
 */

(function ($, dis) {
    "use strict";

    /**
     * This module loops the dom to collect image wrapper classes.
     * It then adds the images (normal img tags and background images) to the dom
     * with a src based on screen width.
     *
     * @constructor
     * @extends dis.BaseModule
     */
    dis.DynamicHeight = function (objectConfiguration) {

        dis.BaseModule.call(this);

        var defaults = {

            },
        // Define the actual configuration for this instantiation
            configuration = $.extend(defaults, objectConfiguration),
            lastUsedScreenWidth,
            dom = {},
            eventHandlers,
            selfScope = this;



        /**
         * This function gets the data-src from the image wrapper, based on width of the browser window.
         *
         * @param {jQuery} container - Image wrapper element
         * @returns {string}
         */
        function getHeightForContainer(container) {

            var src = container.attr("data-height-xs") || "",
                currentScreenMode = selfScope.currentScreenMode;

            if (currentScreenMode >= selfScope.screenSizes.tablet) {
                src = container.attr("data-height-sm") || src;
            }

            if (currentScreenMode >= selfScope.screenSizes.desktop) {
                src = container.attr("data-height-md") || src;
            }

            if (currentScreenMode >= selfScope.screenSizes.largeDesktop) {
                src = container.attr("data-height-lg") || src;
            }

            return src;
        }



        /**
         * This function gets the image wrapper data atrributes src and alt text,
         * creates a new image and appends it to image wrapper.
         *
         * @param {HTMLElement|jQuery|string|*} currentContainer - Image wrapper element
         */
        function setContainerHeight(currentContainer) {
            var imageContainer = $(currentContainer),
                newHeight = getHeightForContainer(imageContainer);
            imageContainer.css('height', newHeight);
        }

        eventHandlers = {
            refreshAll: function () {
                // If our current screen mode does not match the one we used the last time we made an image lookup,
                // perform a new one now. Otherwise, what would be the point?
                if (lastUsedScreenWidth !== selfScope.currentScreenMode) {
                    dom.elements.each(function() {
                        setContainerHeight(this);
                    });
                    lastUsedScreenWidth = selfScope.currentScreenMode;
                }
            }
        };

        function init() {
            dom.elements = $('.subpage-header, .frontpage-header');
            selfScope.onWindowResize(eventHandlers.refreshAll);
            eventHandlers.refreshAll();
        }

        // Fire our init-function once our framework and DOM is ready.
        selfScope.onReady(init);
    };

    dis.DynamicHeight.prototype = new dis.BaseModule();
    dis.DynamicHeight.constructor = dis.DynamicHeight;

}(jQuery, dis));
