/*jslint esnext: true */
/*global jQuery, dis, _, Modernizr */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: Morsø municipality
 Date of creation: Dec. 16th, 2015
 */


(function ($, dis, underscore) {
    "use strict";


    /**
     * This module handles whatever small tasks don't fit into anything else.
     *
     * @constructor
     * @extends dis.BaseModule
     */
    dis.ui.Miscellaneous = function () {

        // Fire the basemodule-initiator on this module.
        dis.BaseModule.call(this);

        var handlers,
            selfScope = this;


        handlers = {

            handleHoverstatesOnRibbonContentLinks: function () {
                if (!Modernizr.touchevents) {
                    $("a.ribbon__content").hover(
                        function () {
                            $(this).parents(".ribbon:first").addClass("ribbon--hover");
                        },
                        function () {
                            $(this).parents(".ribbon:first").removeClass("ribbon--hover");
                        }
                    );
                }

            },


            handleFloatingColumns: function () {

                // If the screen is smaller than a tablet, we will assume it will never get any larger.
                // In that case, we'll just break at once to save mobile devices the trouble of setting up
                // event listeners that will never be used.
                if (selfScope.currentScreenMode < selfScope.screenSizes.tablet) {
                    return;
                }

                var targetColumns = $(".article__actions__column--floater");
                if (targetColumns.length) {

                    // We'll be lazy and just create an IIFE singleton for each of these
                    // columns.
                    targetColumns.each(function (idx, targetColumn) {
                        (function(column) {

                            var currentOffset,
                                currentHeight,
                                parentHeight,
                                parentOffset,
                                parentElement = column.parents(".article__actions:first"),
                                outerParentElement = column.parents(".article:first"),
                                scrollHandlerBounce;


                            function getParentHeight() {
                                parentOffset = parentElement.position().top;
                                parentHeight = outerParentElement.outerHeight() - parentOffset;
                            }

                            /**
                             * Get the new offset variables for this particular column. This includes the parent
                             * element and any heights we may encounter.
                             */
                           function getNewOffset() {
                                // If we don't reset the transform on the column, the offset will include the transform-
                                // value. And that just plain sucks.
                                column.css({transform: "translate(0,0)", transition: "none"});

                                // Only continue if we're on a desktop or larger.
                                if (selfScope.currentScreenMode >= selfScope.screenSizes.desktop) {
                                    currentOffset = column.offset().top - 50;
                                    currentHeight = column.outerHeight();
                                    getParentHeight();
                                }

                                column.attr("style", "");
                            }


                            /**
                             * Handle scroll events.
                             */

                            function handleScroll() {
                                if (selfScope.currentScreenMode >= selfScope.screenSizes.desktop) {
                                    var newPosition = Math.max(
                                        0,
                                        selfScope.currentWindowScrollPosition - currentOffset
                                    );

                                    // Make sure the element doesn't float outside the parent container.
                                    if (newPosition + currentHeight > parentHeight) {
                                        newPosition = Math.max(0, parentHeight - currentHeight);
                                    }

                                    column
                                        .css("transform", "translateY(" + newPosition + "px)")
                                        .toggleClass("article__actions__column--floater-active", newPosition > 0);
                                }

                            }

                            // We'll just make absolutely sure our scroll-calculator won't be fired too often. Because
                            // of reasons.
                            scrollHandlerBounce = underscore.throttle(handleScroll, 500);

                            /**
                             * Get the new offset positions, and then fire a scroll event.
                             */

                            function reInit() {
                                getNewOffset();
                                scrollHandlerBounce();
                            }


                            function parentHeightChanged() {
                                getParentHeight();
                                scrollHandlerBounce();
                            }


                            /**
                             * This function sets up the callbacks and fires the initial calculations.
                             */

                            function init() {
                                selfScope.onScroll(scrollHandlerBounce);
                                selfScope.onWindowResize(reInit);
                                selfScope.globalDOM.window.on("dis.innerheightchanged", parentHeightChanged);
                                reInit();
                            }

                            // Give resources time to finish (or at least initiate) loading before firing the
                            // functions for the first time.
                            window.setTimeout(init, 3000);

                        }($(targetColumn)));
                    });
                }

            },


            handleKeypressesOnHiddenCheckboxes: function () {

                $("input.hidden-checkbox").keydown(function (e) {
                    if (e.which === 13) {
                        var currentCheckbox = $(this);
                        e.preventDefault();
                        $(this).trigger("click");
                    }
                });

            },


            handleDismissalLinks: function () {

                $("[data-dismissparent]").click(function(e) {
                    e.preventDefault();
                    $(this).parents("[data-dismisstarget]").addClass("hidden");
                });

            },


            handleVideos: function () {
                $(".article__content").fitVids();
            },


            removeHeightFromImages: function () {
                $(".article__content img[style]").css("height", "");
            },

            handleExpandingBoxes: function () {
                $(".expandable-content .hidden-checkbox").click(function () {
                    selfScope.globalDOM.window.trigger("dis.innerheightchanged");
                });
            }

        };


        /**
         * Initialization function, which is run when the module is "booting".
         */
        function init() {

            // Fire ALL handlers when ready.
            underscore.each(handlers, function(functionReference) { functionReference(); });

        }

        // Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
        // in dis.base.js.
        selfScope.onReady(init);

    };

    dis.ui.Miscellaneous.prototype = new dis.BaseModule();
    dis.ui.Miscellaneous.constructor = dis.ui.Miscellaneous;

}(jQuery, dis, _));