/*global jQuery, dis, _ */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: Morsø municipality
 Date of creation: Jan. 13th, 2015
 */


(function ($, dis, underscore) {
    "use strict";


    /**
     * This module handles the autocomplete-functionality for the search bar
     *
     * @param objectConfiguration - The configuration for the object
     * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
     * @constructor
     * @extends dis.BaseModule
     */
    dis.Autocomplete = function (objectConfiguration) {

        // Fire the basemodule-initiator on this module.
        dis.BaseModule.call(this);

        var defaults = {
                apiEndpoint: "/umbraco/morsoe/search/index",
                maxSuggestionCount: 5,
                classNames: {
                    hidden: 'hidden',
                    containerWrapper: 'autocomplete__container',
                    suggestionWrapper: 'autocomplete-suggestion',
                    suggestionTitle: 'autocomplete-suggestion__title',
                    suggestionCategory: 'autocomplete-suggestion__category',
                    selectedSuggestion: 'autocomplete-suggestion--selected'
                },
                targetFieldSelector: 'input[type="search"]'
            },
            configuration = $.extend(true, defaults, objectConfiguration),
            dom = {},
            eventHandlers,
            runningQuery,
            cachedQueries = {},
            cachedResults = {},
            currentSearchWord = "",
            rawSuggestionDOM = [],
            suggestionCount = 0,
            fieldSelected = false,
            selectedSuggestion,
            siteID,
            selfScope = this;






        /**
         * (Re)build the contents of the suggestion box from an array of data.
         *
         * @param {Array} suggestions
         */
        function buildSuggestionBox(suggestions) {

            // Make sure no suggestion is marked as selected internally
            selectedSuggestion = undefined;

            // Set the internal counter to how many suggestions we have.
            suggestionCount = suggestions.length;

            // Empty the container so we're ready.
            dom.autocompleteContainer.empty();

            // Use underscore.map to iterate through our items, and build an array of raw DOM-references
            // in the process.
            rawSuggestionDOM = underscore.map(suggestions, function (suggestion) {

                // Build stuff!
                var outerContainer = $('<div>', {'class': configuration.classNames.suggestionWrapper, 'data-url': suggestion.url }),
                    title = $('<div>', {'class': configuration.classNames.suggestionTitle, 'text': suggestion.title }),
                    category = $('<div>', {'class': configuration.classNames.suggestionCategory, 'text': suggestion.category });

                // Append stuff!
                outerContainer.append(category, title);

                // Make sure stuff happens when we click stuff!
                outerContainer.click(eventHandlers.onSuggestionSelect);

                // Append stuff!
                dom.autocompleteContainer.append(outerContainer);

                // Get the raw DOM-reference to the new suggestion, and return it. It will be added to the resulting
                // array by Underscore.
                return outerContainer.get();
            });

            // Show or hide the autocomplete-container based on whether or not we have suggestions, and if the
            // parent field is selected or not.
            dom.autocompleteContainer.toggleClass(configuration.classNames.hidden, !suggestionCount && !fieldSelected);

        }





        /**
         * Load the suggestions from the server.
         */
        function loadSuggestions() {

            if (runningQuery && runningQuery.readyState !== 4) {
                runningQuery.abort();
            }

            var newSearchWord = $.trim(dom.targetField.val());

            // Only confinue if the search word is something different than what we searched for the last time.
            if (newSearchWord && newSearchWord !== currentSearchWord) {

                // Only start a new query if we haven't searched for this search word already
                if (!cachedQueries[newSearchWord]) {

                    cachedQueries[newSearchWord] = runningQuery = $.ajax({
                        url: configuration.apiEndpoint,
                        dataType: 'json',
                        data: { q: newSearchWord, siteid: siteID }
                    });

                    // Store the result. For some reason, subsequent callbacks fail in jQuery (no response data given),
                    // so apparently this is needed.
                    cachedQueries[newSearchWord].then(function (response) {

                        var subset;

                        if (response.constructor === Array) {
                            subset = response.splice(0, configuration.maxSuggestionCount);
                        } else {
                            subset = [];
                        }

                        cachedResults[newSearchWord] = subset;
                    });
                }

                // Perform an exorcism. And also set up a callback for the AJAX handler to always fire, even for
                // cached queries.
                cachedQueries[newSearchWord].then(function () {

                    currentSearchWord = newSearchWord;
                    buildSuggestionBox(cachedResults[newSearchWord]);

                });

            }

        }





        /**
         * Mark the currently selected option as being... well, selected.
         */
        function emphasizeSelectedOption() {
            if (typeof selectedSuggestion === "number") {
                $(rawSuggestionDOM[selectedSuggestion]).addClass(configuration.classNames.selectedSuggestion).siblings().removeClass(configuration.classNames.selectedSuggestion);
            } else {
                dom.autocompleteContainer.find("." + configuration.classNames.selectedSuggestion).removeClass(configuration.classNames.selectedSuggestion);
            }
        }






        eventHandlers = {
            onSuggestionSelect: function () {
                var currentSuggestion = $(this);

                window.location = currentSuggestion.attr("data-url");
            },


            onArrowDown: function (e) {
                if (suggestionCount) {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }

                    // Move the selection down, or to the top if it's outside bounds.
                    selectedSuggestion = typeof selectedSuggestion === "number" ? selectedSuggestion + 1 : 0;
                    if (selectedSuggestion > suggestionCount - 1) {
                        selectedSuggestion = 0;
                    }

                    emphasizeSelectedOption();
                }
            },


            onArrowUp: function (e) {
                if (suggestionCount) {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }

                    // Move the selection up, or to the bottom if it's outside bounds.
                    selectedSuggestion = typeof selectedSuggestion === "number" ? selectedSuggestion - 1 : suggestionCount - 1;
                    if (selectedSuggestion < 0) {
                        selectedSuggestion = suggestionCount - 1;
                    }

                    emphasizeSelectedOption();
                }

            },


            onEnter: function (e) {
                if (typeof selectedSuggestion === "number") {
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }

                    // Run the selection function thingie.
                    eventHandlers.onSuggestionSelect.call(rawSuggestionDOM[selectedSuggestion]);
                }
            },



            onESC: function (e) {

                // If a suggestion is selected, unselect it but leave the box open.
                if (typeof selectedSuggestion === "number") {
                    selectedSuggestion = undefined;
                    emphasizeSelectedOption();
                    if (e && e.preventDefault) {
                        e.preventDefault();
                    }

                } else {
                    suggestionCount = 0;
                    dom.targetField.blur();
                }
            },


            onFieldFocus: function () {
                if (suggestionCount) {
                    dom.autocompleteContainer.removeClass(configuration.classNames.hidden);
                }
                fieldSelected = true;
            },

            onFieldBlur: function () {
                dom.autocompleteContainer.addClass(configuration.classNames.hidden);
                fieldSelected = false;
            },


            onFieldKeydown: function (e) {
                switch (e.which) {
                case 13:
                    eventHandlers.onEnter(e);
                    break;
                case 27:
                    eventHandlers.onESC(e);
                    break;
                case 38:
                    eventHandlers.onArrowUp(e);
                    break;
                case 40:
                    eventHandlers.onArrowDown(e);
                    break;
                }

            },

            onFieldChanged: function () {
                eventHandlers.throttledEvents.loadSuggestions();
            }
        };



        eventHandlers.throttledEvents = {
            loadSuggestions: underscore.throttle(loadSuggestions, 500),
            onFieldChanged: underscore.throttle(eventHandlers.onFieldChanged, 500),
            onFieldBlur: underscore.debounce(eventHandlers.onFieldBlur, 500)
        };






        /**
         * Initialization function, which is run when the module is "booting".
         */
        function init() {

            dom.container = $(configuration.container);

            if (dom.container.length) {

                dom.autocompleteContainer = $('<div>', { 'class': configuration.classNames.containerWrapper + " " + configuration.classNames.hidden });
                dom.container.append(dom.autocompleteContainer);

                siteID = dom.container.attr("data-site-id");

                dom.targetField = dom.container.find(configuration.targetFieldSelector);
                dom.targetField
                    .focus(eventHandlers.onFieldFocus)
                    .blur(eventHandlers.throttledEvents.onFieldBlur)
                    .keydown(eventHandlers.onFieldKeydown)
                    .keypress(eventHandlers.throttledEvents.onFieldChanged)
                    .keyup(eventHandlers.throttledEvents.onFieldChanged)
                    .change(eventHandlers.throttledEvents.onFieldChanged);

            }
        }





        // Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
        // in dis.base.js.
        selfScope.onReady(init);

    };

    dis.Autocomplete.prototype = new dis.BaseModule();
    dis.Autocomplete.constructor = dis.Autocomplete;

}(jQuery, dis, _));