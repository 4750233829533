/*global jQuery, _ */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: ...
 Date of creation: ...
 */

var dis = dis || {};

(function ($, dis, underscore) {
    "use strict";

    dis.ui = {};

    /**
     * The base module we'll expand on.
     *
     * @class
     * @constructor
     */
    dis.BaseModule = function () {};



    /**
     * This will be SHARED across all instances, and populated in the IIFE that sets up onReady(), a bit further down.
     *
     * @type {object}
     */
    dis.BaseModule.prototype.globalDOM = {};




    /**
     * This IIFE returns a function which performs actions once jQuery is prepared and ready.
     * You can use the resulting function like this:
     *
     *     this.onReady(function() {});
     *
     * ... which will fire your function once the DOM is ready and jQuery is prepared.
     *
     * @type {function}
     */
    dis.BaseModule.prototype.onReady = (function () {

        // First, set up a temporary queue to hold our various callback functions.
        var functionQueue = [],
        // Also, we'll need an internal indication of whether or not jQuery is ready.
            domIsReady = false,
            returnFunction;

        // Once jQuery is ready, do this.
        $(function () {
            var i;

            // Store references to body and window in variables that are globally available. This means we'll
            // only need to target them ONCE per page load.
            dis.BaseModule.prototype.globalDOM.body = $("body");
            dis.BaseModule.prototype.globalDOM.main = $("main");
            dis.BaseModule.prototype.globalDOM.window = $(window);

            domIsReady = true;

            // Fire any and all functions that are queued up to this point.
            for (i = 0; i < functionQueue.length; i++) {
                functionQueue[i]();
            }
        });

        /**
         * Store a function reference that will be fired as soon as the DOM and jQuery is ready.
         *
         * @param {function} functionReference
         */
        returnFunction = function (functionReference) {
            if (typeof functionReference === "function") {
                if (!domIsReady) {
                    functionQueue.push(functionReference);
                } else {
                    functionReference();
                }
            }
        };

        // Return a function that can store a function reference and queue it until the DOM is ready to fire.
        return returnFunction;
    }());





    /**
     * The current width of the window, accessible in your constructor through this.currentWindowWidth
     *
     * @type {number}
     */
    dis.BaseModule.prototype.currentWindowWidth = 0;





    /**
     * The current height of the window, accessible in your constructor through this.currentWindowHeight
     *
     * @type {number}
     */
    dis.BaseModule.prototype.currentWindowHeight = 0;






    /**
     * An array of screen sizes, in order to easily being able to identify the current screen size as defined
     * by the active media query.
     *
     * @type {{unknown: number, mobile: number, largeMobile: number, tablet: number, desktop: number, largeDesktop: number, veryLargeDesktop: number}}
     */
    dis.BaseModule.prototype.screenSizes = {
        unknown: 0,
        mobile: 1,
        largeMobile: 2,
        tablet: 3,
        desktop: 4,
        largeDesktop: 5,
        veryLargeDesktop: 6
    };





    /**
     * The current screen mode. This will correspond to one of the predefined screen sizes.
     *
     * @type {number}
     */
    dis.BaseModule.prototype.currentScreenMode = dis.BaseModule.prototype.screenSizes[1];




    /**
     * Using the IIFE, "onWindowResize" becomes a function that takes a single argument. You can use it from within
     * your own constructor like so:
     *
     *     this.onWindowResize(function () { });
     *
     * This will fire your function every time the window is resized, or the window orientation changes. Neato!
     *
     * @type {function}
     */
    dis.BaseModule.prototype.onWindowResize = (function () {

        var functionQueue = [],
            underscoreThrottle,
            mqIndicator,
            screenSizeCount = underscore.size(dis.BaseModule.prototype.screenSizes),
            returnedFunction;


        /**
         * Get various window sizes - width, height etc. - and store them on our prototype.
         */
        function getWindowSizes() {
            // Get the width of our media query indicator. This will return the width as defined in main.scss!
            var currentMQIndicatorWidth = mqIndicator.width();

            dis.BaseModule.prototype.currentWindowWidth = dis.BaseModule.prototype.globalDOM.window.width();
            dis.BaseModule.prototype.currentWindowHeight = dis.BaseModule.prototype.globalDOM.window.height();

            // Store the screen mode, if it's inside our screen size array (defined above). This enables you to match the
            // current screen size with an actual media query, instead of handling the checks in your JS.
            dis.BaseModule.prototype.currentScreenMode = currentMQIndicatorWidth < screenSizeCount ? currentMQIndicatorWidth : 0;
        }


        /**
         * This function is run every time the window is resized. It's on a throttle, though, so it won't be fired too
         * often.
         */
        function performCalculationCallback() {
            var i;

            // Get our current window size(s)
            getWindowSizes();

            // Fire all queued functions that other scripts may have registered.
            for (i = 0; i < functionQueue.length; i++) {
                functionQueue[i]();
            }
        }

        // Set up a throttle, so our callback function is fired a maximum of once per second.
        underscoreThrottle = underscore.throttle(performCalculationCallback, 1000);

        // Utilize our prototyped "ready"-function to bind an event for handling window resizes and orientation changes.
        dis.BaseModule.prototype.onReady(function () {
            dis.BaseModule.prototype.globalDOM.window.bind("resize orientationchange", underscoreThrottle);

            // Create a media query indicator that we can use to check the current screen size, compared to our media queries.
            mqIndicator = $("<div></div>", { id: "mediaQueryIndicator"});
            mqIndicator.css("display", "none");
            dis.BaseModule.prototype.globalDOM.body.append(mqIndicator);

            // Get our current window size, because we might need the data.
            getWindowSizes();
        });


        /**
         * Store a function reference to be fired every time the window is resized.
         *
         * @param {function} functionReference
         */
        returnedFunction = function (functionReference) {
            if (typeof functionReference === "function") {
                functionQueue.push(functionReference);
            }
        };

        // Return a function that can store an incoming function reference and fire it once the window is resized.
        return returnedFunction;

    }());




    /**
     * Using the IIFE, "onScroll" becomes a function that takes a single argument. You can use it from within
     * your own constructor like so:
     *
     *     this.onScroll(function () { });
     *
     * This will fire your function every time the window is scrolled.
     *
     * @type {function}
     */
    dis.BaseModule.prototype.onScroll = (function () {

        var functionQueue = [],
            underscoreThrottle,
            returnedFunction;


        /**
         * Get various window sizes - width, height etc. - and store them on our prototype.
         */
        function getScrollPosition() {
            dis.BaseModule.prototype.currentWindowScrollPosition = dis.BaseModule.prototype.globalDOM.window.scrollTop();
        }


        /**
         * This function is run every time the window is resized. It's on a throttle, though, so it won't be fired too
         * often.
         */
        function performCalculationCallback() {
            var i;

            // Get our current window size(s)
            getScrollPosition();

            // Fire all queued functions that other scripts may have registered.
            for (i = 0; i < functionQueue.length; i++) {
                functionQueue[i]();
            }
        }

        // Set up a throttle, so our callback function isn't fired too often.
        underscoreThrottle = underscore.throttle(performCalculationCallback, 300);

        // Utilize our prototyped "ready"-function to bind an event for handling window resizes and orientation changes.
        dis.BaseModule.prototype.onReady(function () {
            dis.BaseModule.prototype.globalDOM.window.bind("scroll", underscoreThrottle);

            // Get our current window size, because we might need the data.
            getScrollPosition();
        });


        /**
         * Store a function reference to be fired every time the window is resized.
         *
         * @param {function} functionReference
         */
        returnedFunction = function (functionReference) {
            if (typeof functionReference === "function") {
                functionQueue.push(functionReference);
            }
        };

        // Return a function that can store an incoming function reference and fire it once the window is resized.
        return returnedFunction;

    }());



    dis.BaseModule.prototype.isMobileSafari = (/iP/.test(navigator.platform) && /Safari/i.test(navigator.userAgent));



}(jQuery, dis, _));