/*jslint esnext: true */
/*global jQuery, dis, _, Modernizr */

/* DIS/PLAY Script
 Author's name: Anders Gissel
 Modified by:
 Client name: Morsø municipality
 Date of creation: Dec. 16th, 2015
 */


(function ($, dis, underscore) {
    "use strict";


    /**
     * This module handles the header and whatever
     *
     * @param objectConfiguration - The configuration for the object
     * @param {jQuery|HTMLElement|string|*} objectConfiguration.container - The DOM-container for this instantiation
     * @constructor
     * @extends dis.BaseModule
     */
    dis.ui.Header = function (objectConfiguration) {

        // Fire the basemodule-initiator on this module.
        dis.BaseModule.call(this);

        var defaults = {},
            configuration = $.extend(defaults, objectConfiguration),
            dom = {},
            eventHandlers,
            selfScope = this;


        eventHandlers = {

            /**
             * Add or remove the "scroll-state" class on our header container, based on whether or not our scroll
             * position is at least 100px from the page top. This allows us to do stuff to the menu using pure CSS.
             */
            onScrollEvent: function () {
                dom.container
                    .toggleClass("scroll-state", selfScope.currentWindowScrollPosition > 100)
                    .toggleClass("no-scroll-state", selfScope.currentWindowScrollPosition <= 100);
            },


            /**
             * This function fixes the side menu height on browsers that do not support the VH unit natively.
             * Oh, and also on Safari on iOS, since that piece of crap software has a mind of its own.
             */
            fixSidemenuHeight: function () {
                if (selfScope.currentScreenMode >= selfScope.screenSizes.tablet) {
                    dom.headerMenu.css("height", selfScope.currentWindowHeight + (selfScope.isMobileSafari ? 60 : 0));
                } else {
                    dom.headerMenu.css("height", "");
                }
            },


            /**
             * Add or remove a class to the body, based on whether or not the sidemenu (main menu) is open.
             */
            handleSideMenuActivation: function () {
                var isChecked = dom.sideMenuToggleCheckbox.prop("checked");
                selfScope.globalDOM.body.toggleClass("sidemenu-open", isChecked);
                dom.headerMenu.attr("aria-hidden", isChecked ? "false" : "true");
                if (!isChecked) {
                    // Blur the toggle-checkbox after 100ms. This removes "visual focus" from the button.
                    window.setTimeout(function () {
                        dom.sideMenuToggleCheckbox.blur();
                    }, 100);
                }
            },


            /**
             * This function forces all sibling menus to close when an item is toggled (either on or off). This ensures
             * that only one menu may be opened per level at any time. Sadly there's no way to handle this in CSS, or I
             * would have.
             */
            handleCheckboxSelectionInMainMenu: function () {
                var element = $(this),
                    parent = element.parent();

                parent.siblings().find(":checkbox").prop("checked", false);
            },


            /**
             * When the search menu toggle icon is clicked on mobile (that's the only place it'll show up), we'll wait
             * a bit, and then check to see if the search menu is open. If it is, we'll set focus in the search field
             * so the user can start typing immediately.
             */
            handleSearchMenuToggle: underscore.debounce(function () {
                if (dom.searchMenuToggleCheckbox.prop("checked")) {
                    dom.searchInput.focus();
                }
            }, 250)

        };


        /**
         * Initialization function, which is run when the module is "booting".
         */
        function init() {

            dom.container = $(configuration.container);

            if (dom.container.length) {
                selfScope.onScroll(eventHandlers.onScrollEvent);


                // Set up some events for our main menu.
                dom.headerMenu = $("#headerMenu");
                dom.headerMenu.find(":checkbox").click(eventHandlers.handleCheckboxSelectionInMainMenu);
                if (!Modernizr.cssvhunit || selfScope.isMobileSafari) {
                    selfScope.onWindowResize(eventHandlers.fixSidemenuHeight);
                    eventHandlers.fixSidemenuHeight();
                }


                // Add or remove a class on <body> based on whether or not the main menu is open.
                dom.sideMenuToggleCheckbox = dom.container.find("#sideMenuActive");
                dom.sideMenuToggleCheckbox.click(eventHandlers.handleSideMenuActivation);
                eventHandlers.handleSideMenuActivation();


                // When the menu search toggle thingie is clicked on mobile, we'll want to set focus in the search field
                // immediately.
                dom.searchMenuToggleCheckbox = dom.container.find("#searchBarActive");
                dom.searchMenuToggleLabel = dom.container.find(".header__search-toggle");
                dom.searchInput = dom.container.find("input[type=search]");
                dom.searchMenuToggleLabel.click(eventHandlers.handleSearchMenuToggle);
            }



        }

        // Once everything is ready, run the init-function to get the ball rolling. The "onReady()" function lives
        // in dis.base.js.
        selfScope.onReady(init);

    };

    dis.ui.Header.prototype = new dis.BaseModule();
    dis.ui.Header.constructor = dis.ui.Header;

}(jQuery, dis, _));